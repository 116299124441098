import React from "react";
import { Col, Row } from "reactstrap";

import styles from "./tierCard.module.scss";
import check from "../../images/icons/check.svg";

const TierCard = ({ title, requirements, weight }) => (
  <div className={styles.card}>
    <h4 className={`text-center ${styles.title}`}>{title}</h4>
    <Row className={styles.wrapper}>
      <Col xs="8">
        <h6 className={styles.label}>Requirements</h6>
        <p className={styles.value}>{requirements}</p>
        <h6 className={styles.label}>Pool Weight</h6>
        <p className={styles.value}>{weight}</p>
      </Col>
      <Col xs="4" className="pl-0">
        <h6 className={`${styles.label} text-center`}>Voting Power</h6>
        <img alt="check" className={styles.check} src={check} width="30" />
        <h6 className={`${styles.label} text-center`}>Guaranteed Allocation</h6>
        <img alt="check" className={styles.check} src={check} width="30" />
      </Col>
    </Row>
    <a href="/staking">
    <h4 className={`text-center ${styles.subtitle}`}>STAKE</h4>
    </a>
  </div>
);

export default TierCard;
