import React from "react";
import { Col, Row, Container } from "reactstrap";

import TokenMetrios from "../tokenMetrios/tokenMetrios";
import styles from "./tokenomics.module.scss";

const Tokenomics = () => (
  <div className={styles.tokenomics} id="tokenomics">
    <section>
      <Container>
        <Row>
          <Col lg="5" md={12}>
            <h2 className="text-center text-md-left section-title">
              pNAUT <br></br>TOKENOMICS
            </h2>
            <p className="text-center text-md-left">
            The native token of Astronaut is an ERC-20 token called pNAUT, Which is a
             fixed supply token to be used for Astronaut platform token for IDO tiers.
            </p>
            <a
              className="d-none d-md-inline"
              target="_blank"
              href="https://dex.apeswap.finance/#/swap?outputCurrency=0x05b339b0a346bf01f851dde47a5d485c34fe220c"
              rel="noreferrer"
            >
              <button className="button">Buy NAUT</button>
            </a>
          </Col>
          <Col lg={7} md="12">
            <TokenMetrios />
          </Col>
        </Row>
      </Container>
    </section>
  </div>
);

export default Tokenomics;
