import React, { useRef } from "react";
import { Col, Row, Container } from "reactstrap";
import "react-alice-carousel/lib/alice-carousel.css";

import TierCard from "../tierCard/tierCard";
import styles from "./tiers.module.scss";

export const SampleTierData = [
  {
    id: 1,
    title: 'Moon',
    requirements: '1000',
    weight: '20'
  },
  {
    id: 2,
    title: 'Mars',
    requirements: '3000',
    weight: '66'
  },
  {
    id: 3,
    title: 'Star',
    requirements: '6000',
    weight: '145'
  },
  {
    id: 4,
    title: 'Galaxy',
    requirements: '15000',
    weight: '400'
  },
]

const Tiers = () => {

  return (
    <div className={styles.tiers} id="tiers">
      <section>
        <Container>
          <Row>
            <Col xs="12">
              <h2 className="section-title text-center">TIERS</h2>
              <p className="text-center">
              Astronaut has a unique tier system that requires users to hold a certain amount of pNAUT tokens to participate in its launchpad. It’s a pool weight system based on base allocations. The more pNAUT you stake, the larger allocations you receive by pool weight.
              (Subjective to change)

              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className={styles.slider}>
            {SampleTierData.map(data => <TierCard key={data.id} title={data.title} requirements={data.requirements} weight={data.weight} />)}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Tiers;
