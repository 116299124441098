import React, { useState,useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from './rocketlist.module.scss';
import PoolCardShot from "../../components/poolCard/poolCardShot"; 
import NJW from "../../images/NJW.png";
import PCR from "../../images/PCR.png";
import { API_URL } from "../../BlockchainProvider/config";

const ShotList = () => {

  const [] = useState();

  const [SampleData,setSampleData] = useState([]);

  useEffect(() => {
    getdata()
  },[])

  const getdata = async () => {
    let _data = await fetch(API_URL+"/list/polygon") ;
    _data = await _data.json() ; 
    if(_data.data){
      setSampleData(_data.data)      
    }
  }

  return(
    <div className={styles.details}>
    <section>
    <div className={styles.panel}>
      <Row>
        <Col xs="12">
        
        </Col>
      </Row>
      <Row>
        {SampleData.map(data => <PoolCardShot 

                                  key={data.id} 
                                  {...data} />)}
      </Row>
      </div>

    </section>
    </div>
  );
}

export default ShotList;
