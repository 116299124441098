import React from "react";
import { Row, Col, Container } from "reactstrap";

import { FeatureList } from "../../components/features/features";
import rocket from "../../images/icons/rocket.png";
import helmet from "../../images/icons/helmet.png";
import safe from "../../images/icons/safe.png";

const SampleData = [
  {
    imageUrl: rocket,
    imageAlt: "FAIR AND RUG-PROOF LAUNCHPAD",
    title: "FAIR AND RUG-PROOF LAUNCHPAD",
    description: "Get early-access to private and pre-sales for safe high-quality projects on the Binance Smart Chain, without the need for large sums of capital.",
  },
  {
    imageUrl: safe,
    imageAlt: "LOCKED LIQUIDITY",
    title: "LOCKED LIQUIDITY",
    description: "Projects launching on Astronaut will be required to lock a percentage of their liquidity with a trusted third party. They would also require a thorough KYC check.",
  },
  {
    imageUrl: helmet,
    imageAlt: "Tiered launch",
    title: "Tiered launch",
    description: "Holders of the native NAUT token get access to IDO pools and the opportunity to get into new projects fairly without bots or whales eating the allocation.",
  },
];

const AboutPage = () => (
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" md="9">
        <h2 className="section-title-no-glow text-center">WHAT IS ASTRONAUT ?</h2>
        <p className="text-center">
        Decentralize the way your ideas raise capital. Astronaut enables projects to raise capital on a decentralized, permissionless, and interoperable environment based on the Polygon chain.
        </p>
        {/* <p className="text-center">
          Fair Get early-access to token sales for safe high-quality projects on
          Binance Smart Chain, without the need for large sums of capital.
        </p> */}
      </Col>
    </Row>
    <Row className="justify-content-center">
      <FeatureList features={SampleData} />
    </Row>
  </Container>
);

export default AboutPage;
