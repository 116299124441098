import React from "react";
import { Col, Row, Container } from "reactstrap";
import styles from "./welcome.module.scss";

import twitter from "../../images/icons/twitter.png";
import twitterblack from "../../images/icons/twitterblack.png";
import telegram from "../../images/icons/telegram.png";
import blacktelegram from "../../images/icons/blacktelegram.png";
import medium from "../../images/icons/medium.png";
import blackmedium from "../../images/icons/blackmedium.png";
import poly from "../../images/icons/poly.png";
import blackpoly from "../../images/icons/blackpoly.png";
// import unknown from "../../images/icons/unknown.png";
import unknown from "../../images/icons/global.png";
import globalw from "../../images/icons/globalw.png";
import final from "../../images/Final2.png";
import poweredBy from "../../images/typographic.png";

let Particles = [];

for (let index = 0; index < 1000; index++) {
  Particles.push(<div key={index} className="c" />);
}

const Welcome = () => (
  <div className={[styles.welcome]}>
    <section className={styles.section}>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <h1 className="text-center text-lg-left ">
              NEXT GENERATION POLYGON LAUNCHPAD POWERED BY
            </h1>
            <img alt="Astronaut logo" className={styles.poweredBy} src={poweredBy}/>
            <p className="text-center text-lg-left ">
            Decentralize the way your ideas raise capital. Astronaut enables projects to raise capital on a decentralized, permissionless, and interoperable environment based on the Polygon chain.

            </p>
            <Row className="mb-5">
              <Col className={styles.actions}>
                <a
                  target="_blank"
                  href="https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0xca469963a030a3670ed76832a6a181d280af108d"
                  rel="noreferrer"
                >
                  <button className="button">Buy pNaut</button>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/astronaut_bsc"
                  rel="noreferrer"
                >
                  <button className="button">Telegram</button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col className={styles.social}>
                <a
                  target="_blank"
                  href="https://twitter.com/astronauttoken"
                  rel="noreferrer"
                >
                  
                  <button className="circle button">
                    <img src={twitter} alt="twitter" className="twitter-white" />
                    <img src={twitterblack} alt="twitter" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/astronaut_bsc"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={telegram} alt="telegram" className="twitter-white" />
                    <img src={blacktelegram} alt="telegram" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/5DyazH7vPj"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={medium} alt="discord" className="twitter-white" />
                    <img src={blackmedium} alt="discord" className="twitter-black" />
                  </button>
                </a>
                <a target="_blank" href="https://polygonscan.com/address/0xca469963a030a3670ed76832a6a181d280af108d" rel="noreferrer">
                  <button className="circle button">
                    <img src={poly} alt="binance" className="twitter-white" />
                    <img src={blackpoly} alt="binance" className="twitter-black" />
                  </button>
                </a>
                <a
                  target="_blank"
                  href="https://astronaut.to/"
                  rel="noreferrer"
                >
                  <button className="circle button">
                    <img src={unknown} alt="Tapcoin" className="twitter-black" />
                    <img src={globalw} alt="Tapcoin" className="twitter-white" />
                  </button>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <div >
              <div>
                <img
                  alt="Astronaut"
                  id="logo"
                  className={styles.image_style}
                  src={final}
                />
              </div>

            </div>
          </Col>
        </Row>
      </Container>
        <div className={styles.sky}>
          <div className={styles.stars}></div>
          <div className={styles.stars1}></div>
          <div className={styles.stars2}></div>
          <div className={styles.shooting_stars}></div>
        </div>
    </section>
  </div>
);

export default Welcome;
